<template>
  <div class="dealers-page">
    <ProvidersTable />
  </div>
</template>

<script>
import ProvidersTable from "@/components/provider/ProvidersTable"

export default {
  name: "Dealers",
  components: {
    ProvidersTable,
  },
}
</script>

<style scoped></style>
