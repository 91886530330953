<template>
  <div class="suppliers-table">
    <a-form
      v-if="!onlyList"
      class="ant-advanced-search-form"
      name="advanced_search"
    >
      <div class="filterFields">
        <a-form-item label="Наименование поставщика" class="filterFormItems">
          <a-input
            @change="e => setFilterName(e.target.value)"
            :value="filters.name"
            style="margin-bottom: 5px"
            placeholder="Наименование поставщика"
          ></a-input>
        </a-form-item>
      </div>
      <div class="buttons">
        <a-button type="primary" @click="fetchSuppliers">Поиск</a-button>
        <a-button @click="handleReset">Очистить</a-button>
      </div>
    </a-form>

    <div class="top-buttons create-supplier">
      <a-button
        @click="onCreateProviderClick"
        type="primary"
        icon="user-add"
        class="user-add"
      >
        Создать Поставщика
      </a-button>
    </div>

    <div class="content-container">
      <a-table
        bordered
        :columns="columns"
        :data-source="suppliers"
        :customRow="customRow"
        :rowKey="row => row.id"
        class="ordertable"
        :pagination="false"
      >
        <template slot="operation" slot-scope="text, record">
          <div>
            <a-popconfirm
              title="Уверены, что хотите переместить дилера в архив?"
              ok-text="Архив"
              cancel-text="Отмена"
              placement="topLeft"
              @confirm="event => onDelete(event, record.id)"
            >
              <a-button
                v-if="record.status === 'active'"
                type="danger"
                ghost
                shape="circle"
                icon="delete"
                size="small"
                @click="onDelete"
              />
            </a-popconfirm>
            <!--
            <a-tooltip class="ml-10" title="В архиве">
              <a-button
                type="danger"
                ghost
                shape="circle"
                icon="close"
                size="small"
              />
            </a-tooltip>
            -->
          </div>
        </template>
      </a-table>

      <a-pagination
        class="pagination"
        v-model="currentPage"
        :page-size="paginationOptions.pageSize"
        :total="paginationOptions.totalCount"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex"

export default {
  name: "ProvidersTable",
  props: {
    onlyList: {
      type: Boolean,
      default: () => false,
    },
  },
  mounted() {
    this.fetchSuppliers()
  },
  computed: {
    ...mapGetters({
      columns: "suppliers/columns",
      suppliers: "suppliers/suppliers",
      filters: "suppliers/filters",
      paginationOptions: "suppliers/paginationOptions",
    }),
    currentPage: {
      get() {
        return this.paginationOptions.currentPage
      },
      set(page) {
        this.setCurrentPage(page)
      },
    },
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    async currentPage(page) {
      if (page !== 1) this.fetchSuppliers()
      this.loading = true
      await this.fetchClients()
      this.loading = false
    },
  },
  data() {
    return {
      filterText: "",
      selectedRowKeys: [],
      form: this.$form.createForm(this, { name: "advanced_search" }),
    }
  },
  methods: {
    ...mapActions({
      fetchSuppliers: "suppliers/fetchSuppliers",
      deleteSupplier: "suppliers/deleteSupplier",
    }),
    ...mapMutations({
      setFilterName: "suppliers/SET_FILTER_NAME",
      clearFilters: "suppliers/CLEAR_FILTERS",
      setCurrentPage: "suppliers/SET_CURRENT_PAGE",
    }),
    onCreateProviderClick() {
      this.$router.push("/createprovider")
    },
    async onDelete(event, key) {
      event.stopPropagation()
      if (key || key === 0) {
        await this.deleteSupplier(key)
        await this.fetchSuppliers()
      }
    },
    onSelectChange(selectedRowKeys) {
      console.log("selectedRowKeys changed: ", selectedRowKeys)
      this.selectedRowKeys = selectedRowKeys
    },
    rowClassName(record) {
      if (record.isActive) {
        return "active"
      }
    },
    customRow(record) {
      return {
        on: {
          click: async () => {
            this.$router.push(`/provider/${record.id}`)
          },
        },
      }
    },
    handleReset() {
      this.form.resetFields()
      this.clearFilters()
      this.fetchSuppliers()
    },
  },
}
</script>

<style scoped lang="scss">
.ordertable {
  ::v-deep .ant-table-tbody > tr {
    cursor: pointer;
  }
}
.suppliers-table {
  .active {
    background: #d9d9d9;
  }
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    padding: 12px 5px !important;
  }

  // ?
  /*td {
		text-align: center;
	}*/
}
</style>

<style lang="scss" scoped>
.ant-advanced-search-form {
  padding: 24px;
  background: #fbfbfb;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  margin-bottom: 10px;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.ant-advanced-search-form .ant-form-item {
  display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}

#advanced-search .ant-form {
  max-width: none;
}
#advanced-search .search-result-list {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  padding: 20px 40px;
}
.search-result-text {
  margin: 20px 0 10px 0;
}
.buttons,
.top-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  > button {
    &:not(:first-child) {
      margin-left: 15px;
    }
  }
}
.top-buttons {
  margin: 20px 0 -5px;
}
.leftButtons {
  margin-right: 20px;
}
.rightButtons {
  margin-left: 20px;
}
.filterFields {
  display: flex;
  flex-direction: row;
}
.filterFormItems {
  display: block;
  margin-right: 20px;
}
.selects {
  width: 200px;
}

.create-supplier {
  margin-bottom: 15px;
}
</style>

<style>
th.column-action,
td.column-action {
  text-align: center !important;
}
</style>
